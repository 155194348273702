<template>
  <div class="teacher_data">
    <!-- Start Main Loader -->

    <!-- End Main Loader -->
    <div class="personal_data_wrapper fadeIn">
      <div class="order-details-page mb-5">
        <div class="row py-2 mx-1 justify-content-center align-items-center">
          <div class="col-12">
            <div class="details border-right-0">
              <div class="row">
                <div class="col-12">
                  <div>
                    <div class="customer">
                      <div class="item">
                        <div class="info">
                          <h5 class="title">
                            {{ $t("forms.labels.name") }}
                          </h5>
                          <p>
                            {{ userInfo.full_name }}
                          </p>
                        </div>
                        <div class="icon">
                          <i class="fal fa-user"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="customer" v-if="userInfo.phone">
                      <div class="item">
                        <div class="info">
                          <h5 class="title">
                            {{ $t("forms.labels.phone") }}
                          </h5>
                          <p>
                            {{ userInfo.phone_code + " - " + userInfo.phone }}
                          </p>
                        </div>
                        <div class="icon">
                          <i class="fal fa-phone"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="userInfo.country">
                    <div class="customer">
                      <div class="item">
                        <div class="info">
                          <h5 class="title">
                            {{ $t("forms.labels.country") }}
                          </h5>
                          <p>{{ userInfo.country.name }}</p>
                        </div>
                        <div class="icon">
                          <i class="fal fa-globe"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="userInfo.city">
                    <div class="customer">
                      <div class="item">
                        <div class="info">
                          <h5 class="title">
                            {{ $t("forms.labels.country") }}
                          </h5>
                          <p>{{ userInfo.city.name }}</p>
                        </div>
                        <div class="icon">
                          <i class="fal fa-globe"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="customer">
                      <div class="item">
                        <div class="info">
                          <h5 class="title">{{ $t("labels.isactive") }}</h5>
                          <p>
                            {{
                              userInfo.is_active
                                ? $t("labels.active", { name: "" })
                                : $t("inactive")
                            }}
                          </p>
                        </div>
                        <div class="icon">
                          <i class="fal fa-user-check"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="userInfo.location_description">
                    <div class="customer">
                      <div class="item">
                        <div class="info">
                          <h5 class="title">
                            {{ $t("titles.locationDesc") }}
                          </h5>
                          <p class="line-break">
                            {{ userInfo.location_description }}
                          </p>
                        </div>
                        <div class="icon">
                          <i class="fal fa-globe"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <main>
                    <v-data-table
                      class="elevation-1 thumb diff_table"
                      :headers="headers"
                      :items="orders"
                      :custom-filter="helper_filterSearch"
                      :loading="loading"
                      :loading-text="$t('table.loadingData')"
                      item-key="id"
                      :items-per-page="paginations.items_per_page"
                      hide-default-footer
                    >
                      <!-- ================== You Can use any slots you want ================== -->
                      <!-- Select no data State -->
                      <template v-slot:no-data>
                        {{ $t("table.noData") }}
                      </template>

                      <template v-slot:[`item.index`]="{ index }">
                        {{ index + 1 }}
                      </template>
                      <template v-slot:[`item.client`]="{ item }">
                        <div class="d-flex flex-column align-items-center">
                          <span v-if="item.client">{{
                            item.client.phone_complete_form
                          }}</span>
                          <span v-else>{{ $t("notFound") }}</span>
                        </div>
                      </template>

                      <template v-slot:[`item.is_payment`]="{ item }">
                        <span
                          class="status"
                          :class="item.is_payment ? 'paid' : 'unpaid'"
                        >
                          {{ item.is_payment ? $t("paid") : $t("unpaid") }}
                        </span>
                      </template>
                      <template v-slot:[`item.order_type`]="{ item }">
                        <span class="status">
                          {{
                            item.order_type == "normal"
                              ? $t("ordinary")
                              : $t("sechudle")
                          }}
                        </span>
                      </template>
                      <template v-slot:[`item.address`]="{ item }">
                        <div v-if="item.address">
                          <div
                            @click="showAddress(item.address)"
                            class="text-truncate"
                            style="width: 200px"
                          >
                            {{ item.address.location_description }}
                          </div>
                        </div>
                        <div v-else>{{ $t("notFound") }}</div>
                      </template>
                      <template v-slot:[`item.status`]="{ item }">
                        <div class="action">
                          <div v-if="item.status == 'delivered'">
                            <span class="status success">
                              {{ item.status_trans }}
                            </span>
                          </div>
                          <div v-else>
                            <span class="status canceled">
                              {{ item.status_trans }}
                            </span>
                          </div>
                        </div>
                      </template>
                      <template
                        v-slot:[`item.order_details.delivery_date`]="{ item }"
                      >
                        <span>
                          {{
                            item.order_details.delivery_date
                              ? item.order_details.delivery_date
                              : $t("notFound")
                          }}
                        </span>
                      </template>
                      <template
                        v-slot:[`item.order_details.delivery_timing`]="{ item }"
                      >
                        <span>
                          {{
                            item.order_details.delivery_date
                              ? item.order_details.delivery_date
                              : $t("notFound")
                          }}
                        </span>
                      </template>
                      <template v-slot:[`item.to`]="{ item }">
                        <router-link :to="`/users/show/${item.to.id}`">
                          {{ item.to.fullname }}
                        </router-link>
                      </template>
                      <template
                        v-slot:[`item.is_pickup_from_branch`]="{ item }"
                      >
                        <div>
                          {{
                            item.is_pickup_from_branch
                              ? $t("is_pick_up_from_store")
                              : $t("delievery")
                          }}
                        </div>
                      </template>
                      <!-- End:: Users Routes -->

                      <!-- Start:: Report Reason Modal Button -->
                      <template v-slot:[`item.report_reason`]="{ item }">
                        <v-icon
                          class="show"
                          small
                          @click="
                            controlReportReasonModalModal(item.report_reason)
                          "
                          v-if="item.report_reason"
                        >
                          fal fa-eye
                        </v-icon>

                        <span v-else>_</span>
                      </template>
                      <!-- End:: Report Reason Modal Button -->

                      <!-- Select actions-->
                      <template v-slot:[`item.actions`]="{ item }">
                        <div class="_actions">
                          <v-icon class="show" small @click="showItem(item)">
                            fal fa-eye
                          </v-icon>
                          <!-- <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon> -->
                          <!-- <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon> -->
                        </div>
                      </template>

                      <!-- ======================== Start Top Section ======================== -->
                      <template v-slot:top>
                        <h3 class="title table-title">
                          {{ $t("titles.ordersNum") }}
                          {{ paginations.total }}
                        </h3>
                      </template>
                      <!-- ======================== End Top Section ======================== -->
                    </v-data-table>
                  </main>
                  <!-- End Main Section -->

                  <!-- Start Pagination -->
                  <template>
                    <div
                      class="pagination_container text-center mb-5 d-flex justify-content-end"
                    >
                      <v-pagination
                        v-model.number="paginations.current_page"
                        :length="paginations.last_page"
                        :total-visible="7"
                        @input="fetchData($event)"
                      ></v-pagination>
                    </div>
                  </template>
                  <!-- End Pagination -->
                </div>
                <div class="col-12 fadeIn">
                  <div class="large-map">
                    <GmapMap
                      style="width: 100%; min-height: 300px; max-height: 100%"
                      :center="{
                        lat: userInfo.lat
                          ? userInfo.lat
                          : coordinates_to_edit.lat,
                        lng: userInfo.lng
                          ? userInfo.lng
                          : coordinates_to_edit.lng,
                      }"
                      :zoom="7"
                      :draggable="true"
                      map-type-id="terrain"
                    >
                      <GmapMarker
                        :position="{
                          lat: userInfo.lat
                            ? userInfo.lat
                            : coordinates_to_edit.lat,
                          lng: userInfo.lng
                            ? userInfo.lng
                            : coordinates_to_edit.lng,
                        }"
                        :clickable="false"
                        :draggable="false"
                      />
                    </GmapMap>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["userInfo"],

  data() {
    return {
      center: {},
      markers: null,
      loading: false,
      coordinates_to_edit: { lat: 0, lng: 0 },
      orders: [],
      paginations: {
        current_page: 1,
        last_page: 1,
        items_per_page: 20,
        total: 0,
      },
    };
  },

  computed: {
    isActive() {
      return this.userInfo.is_active ? this.$t("active") : this.$t("inactive");
    },

    isBan() {
      return this.userInfo.is_ban ? this.$t("ban") : this.$t("inban");
    },
    isActiveManager() {
      return this.userInfo.is_ban ? "غير مفعل" : " مفعل";
    },
    headers() {
      if (this.$i18n.locale == "ar") {
        return [
          {
            text: "#",
            align: "center",
            value: "id",
            sortable: true,
            width: "80",
          },
          {
            text: "رقم الطلب",
            align: "center",
            value: "order_num",
            sortable: false,
          },
          {
            text: "رقم العميل",
            align: "center",
            value: "client",
            sortable: false,
          },
          {
            text: "الموقع",
            align: "center",
            value: "address",
            sortable: false,
          },
          {
            text: "الفرع",
            align: "center",
            value: "branch.full_name",
            sortable: false,
          },
          {
            text: "المسافة",
            align: "center",
            value: "distance",
            sortable: false,
          },
          {
            text: "اجمالي السعر",
            align: "center",
            value: "price_details.total_price",
            sortable: false,
          },
          {
            text: "الحالة",
            align: "center",
            value: `status`,
            sortable: false,
            width: "200",
            padding: "2",
          },
          {
            text: "وقت التوصيل",
            align: "center",
            value: "scheduled_at",
            sortable: false,
          },
          {
            text: "حالة الدفع",
            align: "center",
            value: "is_payment_trans",
            sortable: false,
          },
          {
            text: "نوع التوصيل",
            align: "center",
            value: "is_pickup_from_branch",
            sortable: false,
          },

          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      } else {
        return [
          {
            text: "#",
            align: "center",
            value: "id",
            sortable: true,
            width: "80",
          },
          {
            text: "Order Number",
            align: "center",
            value: "order_num",
            sortable: false,
          },
          {
            text: "Client Number",
            align: "center",
            value: "client",
            sortable: false,
          },
          {
            text: "Loaction",
            align: "center",
            value: "address",
            sortable: false,
          },
          {
            text: "Branch",
            align: "center",
            value: "branch.full_name",
            sortable: false,
          },
          {
            text: "Distance",
            align: "center",
            value: "distance",
            sortable: false,
          },
          {
            text: "Total Price",
            align: "center",
            value: "price_details.total_price",
            sortable: false,
          },
          {
            text: "Status",
            align: "center",
            value: `status`,
            sortable: false,
            width: "200",
            padding: "2",
          },
          {
            text: "Delivery Time",
            align: "center",
            value: "scheduled_at",
            sortable: false,
          },
          {
            text: "Pay Status",
            align: "center",
            value: "is_payment_trans",
            sortable: false,
          },

          {
            text: "Delievery Type",
            align: "center",
            value: "is_pickup_from_branch",
            sortable: false,
          },
          {
            text: "Control",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      }
    },
  },

  methods: {
    // ============== Get User Location
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.markers = [
            {
              position: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            },
          ];
          this.center = this.markers[0].position;
          this.coordinates_to_edit.lat = this.center.lat;
          this.coordinates_to_edit.lng = this.center.lng;
        });
      }
    },
    getOrders() {
      this.loading = true;
      this.$axios
        .get(`drivers/${this.userInfo.id}/orders`, {
          params: { page: this.$route.query.page || 1 },
        })
        .then((res) => {
          this.paginations.last_page = res.data.meta?.last_page;
          this.paginations.items_per_page = res.data.meta?.per_page;

          this.paginations.total = res.data.meta?.total;
          this.orders = res.data.data;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },

    fetchData(e) {
      this.$router.replace({ query: { page: e } }).catch(() => {});
    },
    showItem(item) {
      this.$router.push({ path: "/orders/show/" + item.id });
    },
  },
  watch: {
    [`$route.query.page`](newVal) {
      if (newVal) {
        this.getOrders();
      }
    },
  },
  created() {
    this.getLocation();
  },
  mounted() {
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.getOrders();
  },
};
</script>

<style>
.border-right-0 {
  border-right-width: 0 !important;
}
.line-break {
  line-break: anywhere;
}
</style>
